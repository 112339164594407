*{
    font-family: 'Roboto', cursive;
    box-sizing: border-box;
    margin:0;
    padding:0;
}
body { 
  min-height: 100%;
}
h1{
    font-size:3em;
}
.App{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
    min-width: 760px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media only screen and (max-width: 900px){
  *{
    font-size:0.8em;
  }
}