.RunningTimer{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width:fit-content;
    height:100%;
    margin-left:2%;
    padding:1% 5% 1% 5%;
}
@media only screen and (max-width: 1300px) and (min-width: 801px){
    .RunningTimer{
            padding:1% 2% 1% 2%;
        }
    }