.Settings{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:fit-content;
    padding:4% 2% 5% 4%;
    margin-bottom:4%;
}
.outerTimer{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.arrows{
    display:flex;
    flex-direction: column;
    margin-top: 15%;
}