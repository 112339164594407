#workInput{
    height:50px;
    width:50px;
    font-size:2.5em;
    text-align: center;
}
.WorkTimer{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}