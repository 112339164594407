.embedYoutube{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin:2%;
}
#youtubeEmbed{
    padding:3%;
}
@media only screen and (max-width: 800px){
    .embedYoutube{
        margin: 2%;
    }
  }
@media only screen and (max-width: 1300px) and (min-width:801px){
.embedYoutube{
    margin: 2% 0 25% 0;;
}
}