.TimerContainer{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height:100%;
    width:100%;
}
#inputAndTimer{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    width:65%;
    padding:2% 2%;
}
#settingsAndStart{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 800px){
    #inputAndTimer{
        flex-direction:column;
        width:65%;
    }
  }
  

@media only screen and (max-width: 1600px){
    #inputAndTimer{
            width:85%;
        }
    }
@media only screen and (max-width: 1300px){
    #inputAndTimer{
            width:100%;
        }
    }
    