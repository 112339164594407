#themeButtons{
    padding:1%;
}
.Title{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#aboutSection{
    margin-top:.8%;
    width:60%;
    text-align: center;
    padding:1%;
}